<template>
  <div id="landing-page" style="background-image: url(https://images.unsplash.com/photo-1526367790999-0150786686a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80)">
    <div class="logo-box">
        <img src="../../assets/images/logo.svg" alt="Webmagnat Logo">
    </div>
    <div class="app-landing-message">
      <h4> Bine ai venit in MyDelivery </h4>
    </div>
    <div class="go-to-login">
      <router-link to="/login">
        <button class="app-button landing-button">
          Continua
          <i class="fas fa-arrow-right"></i>
      </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  created () {
    // this.$store.dispatch('hideLoading')
  }
}
</script>

<style lang="css" scoped>
</style>
